// @flow
import { css } from "styled-components";
import { media } from "@nested/brand";
import { RichText as PrismicRichText } from "prismic-reactjs";
import { ButtonLink } from "@nested/component-library";
import { ResponsiveImage } from "../../components/ResponsiveImage";

import {
  regularHeading,
  largeHeading,
} from "../../components/Typography/Headings";
import { mediumParagraph } from "../../components/Typography/Paragraphs";

const backgroundStyle = css`
  align-items: center;
  background-color: ${({ theme }) => theme.palette.hague};
  display: flex;
  flex-direction: column;
  padding: 40px 20px;
  ${media.tablet`
    padding: 60px 20px;
  `}
  ${media.desktop`
    padding: 80px 20px;
  `}
`;

const headingStyle = css`
  ${regularHeading}
  color: white;
  margin: 20px 0 0 0;
  text-align: center;
  white-space: pre-wrap;
  ${media.tablet`
    ${largeHeading}
    color: white;
    max-width: 510px;
  `}
`;

const imageWrapperStyle = css`
  background-color: #ffeef1;
  border-radius: 70px 70px 0 0;
  height: 120px;
  margin: 0px 20px 0 20px;
  position: relative;
  width: 101px;
`;

const paragraphStyle = css`
  text-align: center;

  p {
    ${mediumParagraph}
    color: white;
    margin: 20px 0 0 0;
  }

  ${media.tablet`
    max-width: 520px;
  `}
`;

const imageStyle = css`
  left: -15px;
  position: absolute;
  top: 9px;
  width: 125px;
`;

const buttonStyles = css`
  text-align: center;

  &:first-of-type {
    margin-bottom: 10px;
  }
`;

const formStyles = css`
  text-align: center;

  margin-top: 30px;
  position: relative;
  width: 100%;
  max-width: 290px;
  ${media.tablet`
    max-width: 348px;
  `}
`;

type Props = {
  description: PrismicRichText,
  heading: string,
  image: {
    url: string,
    alt: string,
  },
  submitButtonText: string,
};

export const Bookend = ({
  description,
  heading,
  image,
  submitButtonText,
}: Props) => {
  return (
    <div css={backgroundStyle}>
      <div css={imageWrapperStyle}>
        <ResponsiveImage css={imageStyle} src={image.url} alt={image.alt} />
      </div>
      <h3 css={headingStyle}>{heading}</h3>
      <div css={paragraphStyle}>{PrismicRichText.render(description)}</div>
      <div css={formStyles}>
        <ButtonLink
          type="accent"
          css={buttonStyles}
          to="/get-started/onboarding"
        >
          {submitButtonText}
        </ButtonLink>
      </div>
    </div>
  );
};
