// @flow

import { call, put, select } from "redux-saga/effects";
import { request } from "@nested/utils";
import { getConfig } from "@nested/config";

import type { Saga } from "redux-saga";

export const GET_FOR_SALE_PROPERTIES =
  "app/slices/ForSaleList/GET_FOR_SALE_PROPERTIES";
export const FOR_SALE_PROPERTIES_SUCCESS =
  "app/slices/ForSaleList/FOR_SALE_PROPERTIES_SUCCESS";
export const FOR_SALE_PROPERTIES_ERROR =
  "app/slices/ForSaleList/FOR_SALE_PROPERTIES_ERROR";

const { GOOGLE_CLOUD_STORAGE_API_URL } = getConfig();

type State = {
  forSaleProperties: Property[],
  fetched: boolean,
};

const initialState = (): State => ({
  forSaleProperties: [],
  fetched: false,
});

export type GetForSalePropertiesAction = {|
  type: "app/slices/ForSaleList/GET_FOR_SALE_PROPERTIES",
|};

export type ForSalePropertiesErrorAction = {|
  type: "app/slices/ForSaleList/FOR_SALE_PROPERTIES_ERROR",
  payload: { error: any },
|};

export type ForSalePropertiesSuccessAction = {|
  type: "app/slices/ForSaleList/FOR_SALE_PROPERTIES_SUCCESS",
  payload: { properties: Property[] },
|};

type Action =
  | GetForSalePropertiesAction
  | ForSalePropertiesErrorAction
  | ForSalePropertiesSuccessAction;

export const getFetched = (state: { forSalePage: State }): boolean =>
  Boolean(state.forSalePage.fetched);

export function reducer(state: State = initialState(), action: Action): State {
  switch (action.type) {
    case FOR_SALE_PROPERTIES_SUCCESS:
      return {
        ...state,
        fetched: true,
        forSaleProperties: action.payload.properties,
      };
    case FOR_SALE_PROPERTIES_ERROR:
      return {
        ...state,
        fetched: true,
      };
    default:
      return state;
  }
}

export const getForSaleProperties = () => ({
  type: GET_FOR_SALE_PROPERTIES,
});

export const forSalePropertiesSuccessAction = (
  properties: Property[],
): ForSalePropertiesSuccessAction => ({
  type: FOR_SALE_PROPERTIES_SUCCESS,
  payload: { properties },
});

export const forSalePropertiesErrorAction = (
  error: any,
): ForSalePropertiesErrorAction => ({
  type: FOR_SALE_PROPERTIES_ERROR,
  payload: { error },
});

export function* fetchForSaleProperties(): Saga<void> {
  const fetched = yield select(getFetched);
  const apiUrl = GOOGLE_CLOUD_STORAGE_API_URL;

  if (!fetched) {
    try {
      const configUrl = `${apiUrl}/b/street-property-listings/o/all_listings.json?alt=media`;
      const properties = yield call(request, configUrl);
      yield put(forSalePropertiesSuccessAction(properties));
    } catch (err) {
      yield put(forSalePropertiesErrorAction(err));
    }
  }
}
