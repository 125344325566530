// @flow
import { useContext, useState } from "react";
import { css, ThemeContext } from "styled-components";
import { useHistory } from "react-router";
import { Button } from "@nested/component-library";
import { media, TextInput } from "@nested/brand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const inputIcon = css`
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 10;
  font-size: 20px;
`;

const inputWrapperStyle = css`
  position: relative;
  margin: 30px auto 0;
  max-width: 350px;

  ${media.tablet`
    margin: 30px 0;
  `}
`;

const inputIllusionBox = css`
  background-color: white;
  border: 1px solid ${({ theme }) => theme.palette.hague40};
  border-top: none;
  border-radius: 0 0 4px 4px;
  padding: 0 10px 10px;

  button {
    width: 100%;
    height: 50px;
    color: ${({ theme }) => theme.palette.hague130};
  }

  button:hover,
  button:disabled:hover {
    background-color: ${({ theme }) => theme.palette.terracotta90};
    color: ${({ theme }) => theme.palette.hague130};
  }

  button:active,
  button:disabled:active {
    background-color: #ff6d56;
    color: ${({ theme }) => theme.palette.hague130};
  }

  button:disabled {
    background-color: ${({ theme }) => theme.palette.terracotta100};
    color: ${({ theme }) => theme.palette.hague130};
  }
`;

const errorMessageStyle = css`
  margin: 0;
  padding: 0 0 10px 0;
  color: #fe6a50;
  font-weight: 500;
  text-align: left;
`;

type Props = {
  className?: string,
  submitButtonText: string,
};

export const PostcodeCaptureForm = ({ className, submitButtonText }: Props) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const [postcode, setPostcode] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const onSubmit = async (event) => {
    event.preventDefault();
    setSubmitting(true);

    if (postcode.trim() === "") {
      setErrorMessage("Postcode can't be empty!");
      setSubmitting(false);
      return;
    }

    setSubmitting(false);

    history.push(
      `/get-started/onboarding/?postcode=${postcode}&addressId=address_not_found`,
    );
  };

  return (
    <form onSubmit={onSubmit} className={className} css={inputWrapperStyle}>
      <FontAwesomeIcon
        color={theme.palette.terracotta100}
        icon={faMapMarkerAlt}
        css={inputIcon}
      />
      <TextInput
        css={`
          input {
            box-shadow: none;
          }
        `}
        name="postcode"
        className="fs-exclude"
        withIcon
        hasButtonBelow
        placeholder="Enter your postcode"
        value={postcode}
        onChange={(e) => setPostcode(e.target.value.toUpperCase())}
      />
      <div css={inputIllusionBox} className="illusion-box">
        {errorMessage && <p css={errorMessageStyle}>{errorMessage}</p>}
        <Button type="accent" disabled={submitting}>
          {submitButtonText}
        </Button>
      </div>
    </form>
  );
};
